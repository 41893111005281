<template>
  <div class="page-flash-push">
    <FlashPushChart />
    <h1 class="block-line">
      <span style="font-weight: 600">推送消息列表</span>
      &nbsp;
      <el-date-picker
        style="width: 200px"
        v-model="bindDate"
        type="date"
        placeholder="选择日期"
        @change="initPushList"
      ></el-date-picker>&nbsp;
      <el-button type="primary" icon="el-icon-search" @click="initPushList">搜索</el-button>
    </h1>
    <template v-if="pushList.length">
      <div class="push-item" v-for="(push, idx) in pushList" :key="idx">
        <details v-if="push.body && push.body.length">
          <summary>
            <span class="collapse-title">
              {{$filters.formatDate(push.head.push_time, 'HH:mm:ss')}}
              {{push.head.title}}
              <span
                class="mark"
                :style="{color: colorMap[push.head.name]}"
              >{{push.head.name}}</span>
            </span>
          </summary>
          <div class="collapse-content" v-for="sub in push.body" :key="sub.id">
            <span>{{$filters.formatDate(sub.push_time, 'HH:mm:ss')}}</span>
            &nbsp;
            <span>{{sub.title}}</span>
            &nbsp;
            <span class="mark">{{sub.name}}</span>
          </div>
        </details>
        <div class="collapse-title" v-else>
          <span>{{$filters.formatDate(push.head.push_time, 'HH:mm:ss')}}</span>
          &nbsp;
          <span>{{push.head.title}}</span>
          &nbsp;
          <span
            class="mark"
            :style="{color: colorMap[push.head.name]}"
          >{{push.head.name}}</span>
        </div>
      </div>
    </template>
    <div style="text-align: center">
      <el-button v-if="!this.nextend" :loading="nextloading" @click="initPushList(true)">加载更多...</el-button>
      <br />
      <br />
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { fetchArticlePushList } from '@/domains/stats/api'
import FlashPushChart from '@/views/stats/flash-push-chart.vue'

function handlePushData(pushlist = []) {
  return pushlist.map((push) => {
    const articles = push.articles
    let head = {}
    let body = []
    if (articles && articles[0]) {
      const { title, push_time, source: { id, name } = {} } = articles[0]
      head = { title, push_time, id, name }
    }
    if (articles && articles.length > 1) {
      const subs = articles.slice(1)
      body = subs.map((sub) => {
        const { title, push_time, source: { id, name } = {} } = sub
        return { title, push_time, id, name }
      })
    }
    return {
      head,
      body
    }
  })
}

const colorMap = {
  选股宝: '#ee5e55',
  新浪: '#2394ff',
  财联社: '#f3c50f',
  万得: '#d3cdb2',
  金十: '#98bdd7',
  华尔街见闻: '#cc6601'
}

export default defineComponent({
  name: 'FlashPush',
  components: { FlashPushChart },
  data() {
    this.colorMap = colorMap
    return {
      params: {
        start: new Date().setHours(0, 0, 0, 0) / 1000,
        end: Math.ceil(Date.now() / 1000),
        limit: 50,
        cursor: ''
      },
      pushList: [],
      nextloading: false,
      nextend: false
    }
  },
  computed: {
    bindDate: {
      get() {
        return new Date(this.params.start * 1000)
      },
      set(val) {
        this.params.start = val.getTime() / 1000
        this.params.end = val.getTime() / 1000 + 86400
      }
    }
  },
  mounted() {
    this.initPushList()
  },
  methods: {
    initPushList(more) {
      const params = { ...this.params }
      if (more === true) {
        this.nextloading = true
      } else {
        params.cursor = ''
      }
      this.nextend = false
      fetchArticlePushList(params)
        .then((res) => {
          if (res.items && res.items.length) {
            if (params.cursor) {
              this.pushList = this.pushList.concat(handlePushData(res.items))
            } else {
              this.pushList = handlePushData(res.items)
            }
          } else if (!params.cursor) {
            this.pushList = []
          }
          if (res.next_cursor) {
            this.params.cursor = res.next_cursor
          } else if (params.cursor) {
            this.nextend = true
            this.$message.warning('已加载完毕')
          }
        })
        .finally(() => {
          this.nextloading = false
        })
    }
  }
})
</script>
<style lang="scss">
.page-flash-push {
  position: relative;
  .block-line {
    background: #fff;
    padding: 12px 0;
    position: sticky;
    top: 0;
    z-index: 99;
  }
  .collapse-title {
    color: #343434;
    font-size: 15px;
    font-weight: 600;
  }
  .collapse-content {
    color: #626262;
    font-size: 14px;
    margin-top: 4px;
    padding-left: 20px;
  }
  .el-collapse-item__content {
    padding-bottom: 12px;
  }
  .push-item {
    background: #f4f4f4;
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 10px;
    summary {
      cursor: pointer;
    }
  }
}
</style>
