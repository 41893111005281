
import { defineComponent, ref, onMounted, watch } from 'vue'
import { format } from 'date-fns'
import cloneDeep from 'lodash/cloneDeep'
import { fetchArticlePushRate } from '@/domains/stats/api'
import { shortcuts, useHandleChartsInit } from '@/views/stats/constant'

const defaultBetweenTime = [
  new Date(new Date().setHours(0, 0, 0, 0) - 86400 * 7000),
  new Date(new Date().setHours(0, 0, 0, 0))
]
const tempLinesName = ['华尔街见闻', '选股宝', '新浪', '财联社', '金十', '万得']
const color = ['#4992ff', '#ff6e76', '#fddd60aa', '#7cffb2aa', '#58d9f9aa', '#05c091aa']
const defaultChartData = {
  color,
  title: {
    text: '推送领先率'
  },
  tooltip: {
    trigger: 'axis'
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  legend: {
    // type: 'plain'
    data: []
  },
  toolbox: {
    feature: {
      // saveAsImage: {},
    }
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: []
  },
  yAxis: {
    type: 'value'
  },
  series: []
}

export default defineComponent({
  name: 'FlashPushChart',
  props: {},
  setup(props, ctx) {
    const fetchMethod = fetchArticlePushRate
    const echartIns = useHandleChartsInit('#flash-push', 'dark')
    const refBetweenTime = ref<Date[]>(defaultBetweenTime)
    const renderChartData = cloneDeep(defaultChartData)
    const updateLinesByBetweenTime = () => {
      const [start, end] = refBetweenTime.value
      const params = {
        start: Math.round(start.getTime() / 1000),
        end: Math.round(end.getTime() / 1000)
      }
      fetchMethod(params).then((res) => {
        if (!res || !res.items) return
        const xAxis: string[] = []
        const line_jw: any[] = []
        const line_xgb: any[] = []
        const line_xl: any[] = []
        const line_cls: any[] = []
        const line_js: any[] = []
        const line_wd: any[] = []
        const tempLines = [line_jw, line_xgb, line_xl, line_cls, line_js, line_wd]

        res.items.sort((L, R) => {
          return L.day_time - R.day_time
        })
        res.items.forEach((push: { day_time: number; sources: any[] } = { day_time: 0, sources: [] }, itemidx) => {
          const { day_time, sources } = push
          const axis_text = format(day_time * 1000, 'MM-dd')
          xAxis.push(axis_text)
          if (sources && sources.length) {
            sources.forEach((source: any) => {
              const {
                rate,
                source: { id, name }
              } = source
              if (name === '华尔街见闻') {
                line_jw.push([axis_text, (rate || 0).toFixed(2)])
              }
              if (name === '选股宝') {
                line_xgb.push([axis_text, (rate || 0).toFixed(2)])
              }
              if (name === '新浪') {
                line_xl.push([axis_text, (rate || 0).toFixed(2)])
              }
              if (name === '财联社') {
                line_cls.push([axis_text, (rate || 0).toFixed(2)])
              }
              if (name === '金十') {
                line_js.push([axis_text, (rate || 0).toFixed(2)])
              }
              if (name === '万得') {
                line_wd.push([axis_text, (rate || 0).toFixed(2)])
              }
            })
            // 补全数据缺口
            tempLines.forEach((line) => {
              if (line.length < itemidx + 1) {
                line.push([axis_text, 0])
              }
            })
          }
        })
        const renderLines = []
        tempLines.forEach((line, idx) => {
          renderLines.push({
            name: tempLinesName[idx],
            type: 'line',
            data: line
          } as never)
        })
        renderChartData.series = renderLines as never[]
        renderChartData.xAxis.data = xAxis as never[]
        renderChartData.legend.data = tempLinesName as never[]
        echartIns.value.setOption(cloneDeep(renderChartData), true)
      })
    }

    onMounted(() => {
      updateLinesByBetweenTime()
    })

    watch(refBetweenTime, () => {
      if (!refBetweenTime.value) {
        renderChartData.series = []
        echartIns.value.setOption(cloneDeep(renderChartData), true)
        return
      }
      updateLinesByBetweenTime()
    })
    return {
      shortcuts,
      renderChartData,
      refBetweenTime,
      updateLinesByBetweenTime
    }
  }
})
